import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { AppRouteUrls } from '@core/routing/app/route-urls';
import { AuthService, HroadsService } from '@core/services';
import { ErrorCodes } from '@models/error-codes/error-codes';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SSOAuthGuard implements CanActivate {
  constructor(private auth: AuthService, private hroads: HroadsService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree> | boolean {
    const { user, token } = route.queryParams;

    if (user && token) {
      /**
       * Log the user if it comes from the Hroads SSO
       */
      return this.auth.loginFromSSO(user, token).pipe(
        map(success => {
          if (success) {
            return this.router.createUrlTree([''], {
              queryParams: {
                user: null,
                token: null
              },
              queryParamsHandling: 'merge'
            });
          }

          return this.router.parseUrl(`/${AppRouteUrls.ERROR(ErrorCodes.ERROR_401)}`);
        })
      );
    }

    return true;
  }
}
