import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/services';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.canActivate$.pipe(
      switchMap((canActivate: boolean) => {
        if (canActivate) return of(true);

        return this.authService
          .login(state.url, environment.keycloak.clientId)
          .pipe(map(() => false));
      })
    );
  }
}
