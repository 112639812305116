import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';
import { authStorageFactory } from './auth-storage';
import { authConfig } from './auth.config';
import { oAuthModuleConfig } from './oauth-module.config';

@NgModule({
  imports: [HttpClientModule, OAuthModule.forRoot()]
})
export class AuthModule {
  // NOTE: See https://angular.io/guide/singleton-services#prevent-reimport-of-the-greetingmodule
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error(
        `${parentModule.constructor.name} has already been loaded. Import this module in the AppModule only.`
      );
    }
  }

  public static forRoot(): ModuleWithProviders<AuthModule> {
    const clientId: string =
      window.location.href.includes('/admin') ||
      localStorage.getItem('returnUrl')?.includes('admin')
        ? environment.keycloak.adminClientId
        : environment.keycloak.clientId;

    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: { ...authConfig, clientId } },
        { provide: OAuthModuleConfig, useValue: oAuthModuleConfig },
        { provide: OAuthStorage, useFactory: authStorageFactory }
      ]
    };
  }
}
