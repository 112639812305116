import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      titleKey?: string;
      subtitleKey?: string;
      confirmButtonLabelKey?: string;
      cancelButtonLabelKey?: string;
    }
  ) {
    if (!this.data) this.data = {};

    if (!this.data.titleKey) this.data.titleKey = 'are-you-sure';

    if (!this.data.confirmButtonLabelKey) this.data.confirmButtonLabelKey = 'confirm';

    if (!this.data.cancelButtonLabelKey) this.data.cancelButtonLabelKey = 'cancel';
  }
}
