import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ApplicationContext, AuthService, SituationContext } from '@core/services';
import { Product } from '@models/context/product';

@Injectable({ providedIn: 'root' })
export class SituationStepGuard implements CanActivate {
  constructor(
    private context: ApplicationContext,
    private router: Router,
    private situationCtx: SituationContext,
    private authService: AuthService
  ) {}

  public canActivate(): boolean | UrlTree {
    if (
      this.context.product === Product.Connect &&
      this.authService.isAuthenticated &&
      !this.situationCtx?.situation?.applicationStep
    ) {
      return true;
    }

    return this.router.parseUrl('');
  }
}
