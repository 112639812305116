import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AppRouteUrls } from '@core/routing/app/route-urls';
import { ApplicationContext } from '@core/services';
import { Product } from '@models/context/product';

@Injectable({ providedIn: 'root' })
export class ConnectHomeToTimelineGuard implements CanActivate {
  constructor(private context: ApplicationContext, private router: Router) {}

  public canActivate(): boolean | UrlTree {
    if (this.context.product === Product.Connect)
      return this.router.parseUrl(`/${AppRouteUrls.INTENTIONS}`);

    return true;
  }
}
