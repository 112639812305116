import { HttpParams } from '@angular/common/http';
import {
  BacSpecialty,
  HighSchoolClass,
  HighSchoolType,
  Status
} from '@clients/adventure/bean/situation';
import { Waypoint } from '@clients/adventure/bean/waypoint';
import { Step } from '@clients/poi/poi';
import { Definition } from '@models/definition/definition';
import { Gender } from '@models/gender/gender';
import {
  BAC_SPECIALTIES_QUERY_STRING,
  GENDER_QUERY_STRING,
  HIGH_SCHOOL_CLASS_QUERY_STRING,
  HIGH_SCHOOL_TYPE_QUERY_STRING,
  STATUS_QUERY_STRING
} from './situation-query-string.config';
import { ApiSituation } from './situation.api';

/**
 * Get the number of required Bac specialties based on the highschool class and type
 *
 * @param highschoolClass The highschool class
 * @param highschoolType The highschool type
 * @returns The number of required Bac specialties
 */
export const numberOfRequiredBacSpecialties = (
  highschoolClass: HighSchoolClass,
  highschoolType: HighSchoolType
): number => {
  switch (highschoolClass) {
    case HighSchoolClass.FST:
      // First > Gen > 3 specialties
      // First > TECH > 1 specialty
      // First > PRO > 0 specialty
      switch (highschoolType) {
        case HighSchoolType.GEN:
          return 3;
        case HighSchoolType.TECH:
          return 1;
        default:
          return 0;
      }
    case HighSchoolClass.TML:
      // Tml > Gen > 2 specialties
      // Tml > TECH > 1 specialty
      // Tml > PRO > 0 specialty
      switch (highschoolType) {
        case HighSchoolType.GEN:
          return 2;
        case HighSchoolType.TECH:
          return 1;
        default:
          return 0;
      }
    // SEC > no specialties
    default:
      return 0;
  }
};

/**
 * The user situation
 */
export class Situation {
  /**
   * The current diploma or job of the user
   */
  public currentStep?: Step;

  private readonly ANALYTICS_FST = '1ERE';
  private readonly ANALYTICS_BAC = 'Bac1';

  private _gender: Gender = null;
  private _status: Status = null;
  private _highSchoolClass: HighSchoolClass = null;
  private _highSchoolType: HighSchoolType = null;
  private _bacSpecialties: BacSpecialty[] = [];

  // -- GETTERS / SETTERS --

  public get gender(): Gender {
    return this._gender;
  }

  public set gender(gender: Gender) {
    this._gender = gender;

    // Empty all dependent fields
    this.status = null;
  }

  public get status(): Status {
    if (!this.gender) return null;

    return this._status;
  }

  public set status(status: Status) {
    if (this.gender) this._status = status;

    // Empty all dependent fields
    this.highSchoolClass = null;
  }

  public get highSchoolClass(): HighSchoolClass {
    if (!this.status) return null;

    return this._highSchoolClass;
  }

  public set highSchoolClass(highSchoolClass: HighSchoolClass) {
    if (this.status) this._highSchoolClass = highSchoolClass;

    // Empty all dependent fields
    this.highSchoolType = null;
  }

  public get highSchoolType(): HighSchoolType {
    if (!this.highSchoolClass) return null;

    return this._highSchoolType;
  }

  public set highSchoolType(highSchoolType: HighSchoolType) {
    if (this.highSchoolClass) this._highSchoolType = highSchoolType;

    // Empty all dependent fields
    this._bacSpecialties = [];
  }

  public get bacSpecialties(): BacSpecialty[] {
    if (!this.highSchoolType) return [];

    return this._bacSpecialties;
  }

  public setBacSpecialties(bacSpecialties: BacSpecialty[]): void {
    this._bacSpecialties = [...bacSpecialties];
  }

  // -- VALIDATORS --

  public get hasValidGender(): boolean {
    return !!this.gender;
  }

  public get hasValidSituation(): boolean {
    return (
      this.hasValidGender &&
      (Status.middleschool === this.status ||
        Status.pro === this.status ||
        Status.unemployed === this.status ||
        this.hasValidHighSchool ||
        this.hasValidStudent) &&
      (this.highSchoolType === HighSchoolType.GEN || this.applicationStep !== null)
    );
  }

  // -- UTILS --

  public get hasBac(): boolean {
    return this.status?.startsWith(Status.Bac);
  }

  public get hasHighSchoolClass(): boolean {
    return !!this.highSchoolClass;
  }

  public get hasHighSchoolType(): boolean {
    return !!this.highSchoolType;
  }

  public get isStudent(): boolean {
    return this.status === Status.student || this.hasBac;
  }

  public get isHighSchool(): boolean {
    return this.status === Status.highschool;
  }

  public get noNeedSpecialties(): boolean {
    return (
      !this.hasHighSchoolClass ||
      HighSchoolClass.SEC === this.highSchoolClass ||
      HighSchoolType.PRO === this.highSchoolType
    );
  }

  public get needSpecialties(): boolean {
    return !this.noNeedSpecialties && !this.hasValidBacSpecialties;
  }

  public get hasValidBacSpecialties(): boolean {
    return this.bacSpecialties.length === this.numberOfRequiredBacSpecialties;
  }

  /**
   * Get the number of required Bac specialties based on the highschool class and type
   */
  public get numberOfRequiredBacSpecialties(): number {
    return numberOfRequiredBacSpecialties(this._highSchoolClass, this.highSchoolType);
  }

  public get hasApplicationStep(): boolean {
    return !!this.applicationStep;
  }

  private get hasValidHighSchool(): boolean {
    return (
      this.status === Status.highschool &&
      this.hasHighSchoolClass &&
      this.hasHighSchoolType &&
      this.hasValidBacSpecialties &&
      (this.highSchoolType === HighSchoolType.GEN || this.hasApplicationStep)
    );
  }

  private get hasValidStudent(): boolean {
    return !!this.status && Status.student !== this.status && this.status.startsWith(Status.Bac);
  }

  /**
   * Get the global status (student, highschool, middleschool, pro, unemployed)
   */
  public get globalStatus(): Status {
    return this.isStudent ? Status.student : this.status;
  }

  /**
   * The user status defined by it's most accurate status
   */
  public get accurateStatus(): string {
    if (this.isHighSchool)
      return `${this.highSchoolClass}-${this.highSchoolType}`.replace(
        HighSchoolClass.FST,
        this.ANALYTICS_FST
      );

    if (this.status === Status.Bac) return this.ANALYTICS_BAC;

    return this.status;
  }

  /**
   * The user situation
   *
   * @param id The situation id
   * @param gender The user gender
   * @param status The user status (student type, professional or unemployed)
   * @param highSchoolClass If the user is a high school student, its class
   * @param highSchoolType If the user is a high school student, its high school course type
   * @param bacSpecialties If the user is high school student, its bac specialties
   * @param applicationStep The diploma or job of the user within the application of the context
   */
  constructor(
    public id?: string,
    gender: Gender = null,
    status: Status = null,
    highSchoolClass: HighSchoolClass = null,
    highSchoolType: HighSchoolType = null,
    bacSpecialties: BacSpecialty[] = [],
    public applicationStep?: Step
  ) {
    this._gender = gender;

    if (gender) {
      this._status = status;

      if (status === Status.highschool) {
        this._highSchoolClass = highSchoolClass;

        if (highSchoolClass) {
          this._highSchoolType = highSchoolType;

          if (highSchoolType) this._bacSpecialties = bacSpecialties ? [...bacSpecialties] : [];
        }

        if (this.highSchoolType !== HighSchoolType.GEN) {
          this.applicationStep = applicationStep;
        }
      } else if (status === Status.student) {
        this.applicationStep = applicationStep;
      }
    }
  }

  /**
   * Build the Situation object from the Api Situation object
   *
   * @param apiSituation The Api Situation object
   * @returns The new Situation object
   */
  public static build(apiSituation: ApiSituation): Situation {
    return new Situation(
      apiSituation.id,
      apiSituation.gender,
      apiSituation.status,
      apiSituation.highSchoolClass,
      apiSituation.highSchoolType,
      apiSituation.bacSpecialities,
      apiSituation.applicationStep
    );
  }

  /**
   * Check if the situation is compatible with a definition
   *
   * @param definition The definition
   * @returns True if the user situation allows to access to the definition
   */
  public isCompatibleForDefinition(definition: Definition): boolean {
    return definition.statuses.includes(this.status);
  }

  /**
   * Get the Api Situation object from the Situation object
   *
   * @returns The corresponding Api Situation object
   */
  public toApi(): ApiSituation {
    return {
      gender: this.gender || null,
      status: this.status || null,
      highSchoolClass: this.highSchoolClass || null,
      highSchoolType: this.highSchoolType || null,
      bacSpecialities: this.bacSpecialties || [],
      applicationStep: this.applicationStep || null
    } as ApiSituation;
  }

  /**
   * Build query params from the situation
   *
   * @returns The query params
   */
  public toQueryParams(): HttpParams {
    let params = new HttpParams()
      .set(GENDER_QUERY_STRING, this.gender)
      .set(STATUS_QUERY_STRING, this.status);

    if (this.isHighSchool)
      params = params
        .set(HIGH_SCHOOL_CLASS_QUERY_STRING, this.highSchoolClass)
        .set(BAC_SPECIALTIES_QUERY_STRING, this.bacSpecialties.toString())
        .set(HIGH_SCHOOL_TYPE_QUERY_STRING, this.highSchoolType);

    return params;
  }
}
