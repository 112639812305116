import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {
  Status,
  STATUS_DEPRECATED_BAC,
  STATUS_DEPRECATED_HIGHSCHOOL
} from '@clients/adventure/bean/situation';
import { Gender } from '@models/gender/gender';
import { Situation } from '@models/situation/situation';
import {
  BAC_SPECIALTIES_QUERY_STRING,
  GENDER_QUERY_STRING,
  HIGH_SCHOOL_CLASS_QUERY_STRING,
  HIGH_SCHOOL_TYPE_QUERY_STRING,
  STATUS_QUERY_STRING
} from '@models/situation/situation-query-string.config';

@Injectable({ providedIn: 'root' })
export class SituationResolver implements Resolve<Situation> {
  public resolve(route: ActivatedRouteSnapshot): Situation {
    if (!route.queryParams[GENDER_QUERY_STRING] || !route.queryParams[STATUS_QUERY_STRING])
      return null;

    // Handle params
    const situation = new Situation();

    situation.gender = Gender[route.queryParams[GENDER_QUERY_STRING]];

    situation.status =
      Status[route.queryParams[STATUS_QUERY_STRING]] ||
      Status[
        (route.queryParams[STATUS_QUERY_STRING]?.charAt(0).toUpperCase() || '') +
          (route.queryParams[STATUS_QUERY_STRING]?.slice(1) || '')
      ]; // Handle bac* -> Bac*

    if (situation.isHighSchool) {
      situation.highSchoolClass = route.queryParams[HIGH_SCHOOL_CLASS_QUERY_STRING];
      situation.highSchoolType = route.queryParams[HIGH_SCHOOL_TYPE_QUERY_STRING];
      situation.setBacSpecialties(
        route.queryParams[BAC_SPECIALTIES_QUERY_STRING]?.split(',') || []
      );
    }

    // Handle Deprecated status
    if (!situation.status) {
      if (route.queryParams[STATUS_QUERY_STRING] === STATUS_DEPRECATED_HIGHSCHOOL)
        situation.status = Status.highschool;

      if (route.queryParams[STATUS_QUERY_STRING] === STATUS_DEPRECATED_BAC)
        situation.status = Status.Bac;
    }

    return situation;
  }
}
