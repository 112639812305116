import { AdventureType } from '@models/adventure-type/adventure-type';
import { ErrorCodes } from '@models/error-codes/error-codes';
import { WaypointType } from '@models/waypoint/waypoint-type';

export class AppRouteUrls {
  public static readonly ADVENTURE = 'adventure';
  public static readonly INTENTIONS = `intentions`;
  public static readonly AMBASSADORS = `ambassadors`;
  public static readonly PROFILE = 'profile';
  public static readonly SETTINGS = 'profile/settings';
  public static readonly ROADMAPS = 'roadmaps';
  public static readonly SCHOOL = 'school';
  public static readonly SITUATION = 'situation';
  public static readonly EXPEDITION = 'expeditions';
  public static readonly EXPEDITION_LIBRARY = `${AppRouteUrls.EXPEDITION}/library`;
  public static readonly SITUATION_STEP = 'situation/step';

  public static readonly ADVENTURE_MAP = (instanceId: string): string =>
    `${this.ADVENTURE}/${instanceId}/map`;

  public static readonly ADVENTURE_ROADMAP = (instanceId: string): string =>
    `${this.ADVENTURE}/${instanceId}/roadmap`;

  public static readonly EXPEDITION_STEP = (expeditionInstanceId: string, stepId: string): string =>
    `${AppRouteUrls.EXPEDITION_INSTANCE_DETAIL(expeditionInstanceId)}/steps/${stepId}`;

  public static readonly EXPEDITION_STEP_STATIC = (expeditionId: string, stepId: string): string =>
    `${AppRouteUrls.EXPEDITION_DETAIL(expeditionId)}/steps/${stepId}`;

  public static readonly ERROR = (errorCode: ErrorCodes | ':code'): string => `error/${errorCode}`;

  public static readonly EXPEDITION_INSTANCE_DETAIL = (expeditionInstanceId: string): string =>
    `expeditions/${expeditionInstanceId}`;

  public static readonly EXPEDITION_DETAIL = (expeditionId: string): string =>
    `${AppRouteUrls.EXPEDITION_LIBRARY}/${expeditionId}`;

  public static readonly INTENTIONS_WAYPOINT_ITINERARIES = (
    waypointType: WaypointType | ':type',
    waypointId: string
  ): string => `${this.INTENTIONS_WAYPOINT_DETAILS(waypointType, waypointId)}/itineraries`;

  public static readonly INTENTIONS_WAYPOINT = (
    adventureType: AdventureType | WaypointType | ':type'
  ): string => `${this.INTENTIONS}/${adventureType}`;

  public static readonly INTENTIONS_WAYPOINT_DEFINITIONS = (
    adventureType: AdventureType | ':type'
  ): string => `${this.INTENTIONS_WAYPOINT(adventureType)}/definitions`;

  public static readonly INTENTIONS_WAYPOINT_DETAILS = (
    waypointType: WaypointType | ':type',
    waypointId: string
  ): string => `${this.INTENTIONS_WAYPOINT(waypointType)}/${waypointId}`;

  public static readonly INTENTIONS_WAYPOINT_SEARCH = (
    waypointType: WaypointType | ':type'
  ): string => `${this.INTENTIONS_WAYPOINT(waypointType)}/search`;

  public static readonly INTENTIONS_DIPLOMA_MAJOR_SELECT = (
    waypointType: WaypointType | ':type'
  ): string => `${this.INTENTIONS_WAYPOINT(waypointType)}/select`;
}
