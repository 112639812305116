import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AppRouteUrls } from '@core/routing/app/route-urls';
import { ApplicationContext, AuthService, SituationContext } from '@core/services';
import { Product } from '@models/context/product';
import { ErrorCodes } from '@models/error-codes/error-codes';
import { Observable, filter, map } from 'rxjs';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root'
})
export class ApplicationGuard implements CanActivate {
  constructor(
    private router: Router,
    private context: ApplicationContext,
    private situationCtx: SituationContext,
    private authGuard: AuthGuard,
    private authService: AuthService
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> {
    const { product, applicationName } = this.context;
    const { forbiddenApplications, forbiddenProducts } = next.data;

    if (forbiddenProducts?.includes(product) || forbiddenApplications?.includes(applicationName)) {
      return this.router.parseUrl(`/${AppRouteUrls.ERROR(ErrorCodes.ERROR_404)}`);
    }

    if (product === Product.Connect) {
      if (!this.authService.isAuthenticated) {
        return this.authGuard.canActivate(next, state);
      }

      return this.situationCtx?.situation$.pipe(
        filter(Boolean),
        map(situation =>
          situation?.applicationStep
            ? true
            : this.router.parseUrl(`/${AppRouteUrls.SITUATION_STEP}`)
        )
      );
    }

    return true;
  }
}
